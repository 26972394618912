<template>
    <a-card>
        <a-page-header
            :title='"任务管理"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="任务名称">
                        <a-input
                            v-model="form.missionName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="任务品牌">
                        <a-select
                            mode="multiple"
                            v-model="principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="任务状态">
                        <a-select
                            mode="multiple"
                            v-model="missionStatusList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option :key="item.id" v-for="item in statusList">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="项目编码">
                        <a-input-number
                            v-model="form.projectCode"
                            placeholder="请输入"
                            style="width: 100%;"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage({missionId: 0})">新建任务</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => record.missionId"
            :pagination='false'
            :scroll="{x: 2000}"
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="jumpDetailPage(record)"
                    v-if="record.missionStatus == 1"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'详情'"
                    @onClickBtn="jumpFullDetailPage(record)"
                    v-if="record.missionStatus == 2 || record.missionStatus == 3 || record.missionStatus == 4 || record.missionStatus == 5"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'绑定项目'"
                    @onClickBtn="bindProject(record.missionId)"
                    v-if="record.missionStatus != 1 && !record.projectCode"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'选广告主'"
                    @onClickBtn="onCLickOpenRelease(record)"
                    v-if="record.missionStatus == 1"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'暂停'"
                    @onClickBtn="taskAction('disable', record.missionId)"
                    v-if="record.missionStatus == 3"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'开启'"
                    @onClickBtn="taskAction('enable', record.missionId)"
                    v-if="record.missionStatus == 5"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'删除'"
                    @onClickBtn="delTask(record.missionId)"
                    v-if="record.missionStatus == 1 || record.missionStatus == 2"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'复制'"
                    @onClickBtn="jumpDetailPage(record, 'copy')"
                ></base-button>

                <base-button
                    :type="'link'"
                    :title="'修改预算/KPI'"
                    @onClickBtn="editKPI(record.missionId)"
                    v-if="record.missionStatus != 1"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
import dayjs from 'dayjs';
import filters from "@/mirror-modules/filters.js";

const statusList = [
    { id: 1, label: "草稿" },
    { id: 2, label: "已创建，未投放" },
    { id: 3, label: "进行中" },
    { id: 4, label: "结束" },
    { id: 5, label: "暂停" },
]

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            principalIdList: [],
            missionStatusList: [],
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '任务编码',
                    dataIndex: 'missionCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '项目编码',
                    dataIndex: 'projectCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务名称',
                    dataIndex: 'missionName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务状态',
                    customRender:(text, row) => {
                        let label = (statusList.filter(item => item.id == row.missionStatus))[0].label
                        return row.missionStatus == 3 ? <div class="green">{label}</div> : row.missionStatus == 5 ? <div class="red">{label}</div> : label
                    }
                },
                {
                    align: 'center',
                    title: '任务周期',
                    customRender:(text, row) => {
                        return `${dayjs(row.startTime).format('YYYY/MM/DD') || '-'} ~ ${dayjs(row.endTime).format('YYYY/MM/DD') || '-'}` 
                    }
                },
                {
                    align: 'center',
                    title: '投放位置',
                    dataIndex: 'placementDescArr',
                    customRender:(text, row) => {
                        return row.placementDescArr ? row.placementDescArr.toString() : '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDescArr',
                    customRender:(text, row) => {
                        return row.customerSceneDescArr ? row.customerSceneDescArr.toString() : '-'
                    }
                },
                {
                    align: 'center',
                    title: '出价类型',
                    // dataIndex: 'bidTypeDescArr',
                    customRender:(text, row) => {
                        return row.bidTypeDescArr ? row.bidTypeDescArr.toString() : '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务总/日预算（元）',
                    customRender:(text, row) => {
                        return `${filters.changeNum(row.totalBudget, 2, true)} / ${filters.changeNum(row.dailyBudget, 2, true)}`
                    }
                },
                {
                    align: 'center',
                    title: '任务KPI类型',
                    customRender:(text, row) => {
                        return row.kpiType == 1 ? '线索量' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务总KPI',
                    dataIndex: 'totalKpi',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
                {
                    align: 'center',
                    title: '创建时间',
                    dataIndex: 'ctime',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            statusList,
        }
    },
    created() {
        this.getPrincipalList()
        this.getDataList()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.principalIdList = []
            this.missionStatusList = []
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = Object.assign(_form, {
                principalIdList: this.principalIdList.length > 0 ? this.principalIdList.join(',') : undefined,
                missionStatusList: this.missionStatusList.length > 0 ? this.missionStatusList.join(',') : undefined,
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.deliverManage.getTaskList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        //详情
        jumpFullDetailPage(record) {
            this.$router.push({
                path: `/taskManage/taskFullDetail`,
                query: {
                    id: record.missionId,
                }
            })
        },
        //修改、新建
        jumpDetailPage(record, type) {
            this.$router.push({
                path: `/taskManage/detail`,
                query: {
                    id: record.missionId,
                    type: type
                }
            })
        },
        //绑定项目
        bindProject(id) {
            this.$router.push({
                path: `/taskManage/bindProject`,
                query: {
                    id
                }
            })
        },
        //开启
        onCLickOpenRelease(record) {
            if(record.missionStatus == 1) {
                this.$router.push({
                    path: `/taskManage/openReleaseList`,
                    query: {
                        id: record.missionId
                    }
                })
            }
            if(record.missionStatus == 5) {
                this.$api.core.deliverManage.enableTask(record.missionId).then(res => {
                    if(res.code == 200) {
                        this.$message.success('操作成功')
                        this.getDataList()
                    } else {
                        this.$message.error(`操作失败,${res.message}`)
                    }
                })
            }
        },
        //删除
        delTask(id) {
            let that = this
            this.$confirm({
                title: '确定删除该任务吗？',
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$api.core.deliverManage.delTask(id).then(res => {
                        if(res.code == 200) {
                            that.$message.success('删除成功')
                            that.getDataList()
                        } else {
                            that.$message.error('删除失败')
                        }
                    })
                }
            })
        },
        //暂停
        taskAction(type, missionId) {
            let body = {
                type, 
                missionId
            }
            this.$api.core.deliverManage.taskAction(body).then(res => {
                if(res.code == 200) {
                    this.$message.success('操作成功')
                    this.getDataList()
                } else {
                    this.$message.error(`操作失败,${res.message}`)
                }
            })
        },
        //修改预算/KPI
        editKPI(id) {
            this.$router.push({
                path: `/taskSchedule/detail`,
                query: {
                    id
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
</style>